import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const JsonLd = ({ type, title, description, imageUrl, date, update, category }) => {
  const { site } = useStaticQuery(query);

  //更新日がない場合公開日を代入
  const modified = update || date;

  // 執筆者情報
  const author = [
    {
      '@type': 'Person',
      name: site.siteMetadata.author.name,
      description: site.siteMetadata.author.summary,
    }
  ];

  //公開する人
  const publisher = {
    '@type': 'Organization',
    name: site.siteMetadata.title,
    description: site.siteMetadata.description,
    logo: {
      '@type': 'ImageObject',
      url: `${site.siteMetadata.image}`,
      width: 72,
      height: 72
    }
  }

  // メインデータ
  const jsonLdStructuredData = [];
    switch (type) {
      case 'post':
        jsonLdStructuredData.push({
          "@context": "https://schema.org/",
          "@type": "BlogPosting",
          author,
          publisher,
          headline: title,
          description: description,
          datePublished: date,
          dateModified: modified,
          image: {
            "@type": "ImageObject",
            url: imageUrl
          }
        })
        jsonLdStructuredData.push({
          "@context": "https://schema.org/",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              '@type': 'ListItem',
              position: 1,
              name: 'Home',
              item: site.siteMetadata.siteUrl,
            },
            {
              '@type': 'ListItem',
              position: 2,
              name: category,
              item: site.siteMetadata.siteUrl + '/categories/' + category,
            }
          ]
        })
        break;
      case 'top':
        jsonLdStructuredData.push({
          "@context": "https://schema.org/",
          "@type": "WebSite",
          name: site.siteMetadata.title,
          url: site.siteMetadata.siteUrl,
          description: site.siteMetadata.description,
          publisher
        })
        break;
      default:
    }

  return (
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(jsonLdStructuredData)}</script>
      </Helmet>
  );
};

export default JsonLd;

const query = graphql`
  query Metadata {
    site {
      siteMetadata {
        title
        siteUrl
        image
        author {
          name
          summary
        }
        description
      }
    }
  }
`